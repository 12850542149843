import { Box, Button, Typography } from '@mui/material'
import React from 'react'
import Footer from '../Components/Footer'
import { Margin } from '@mui/icons-material'

export default function NotFound() {
  return (
   
   
    <div >
     <Box textAlign="center"  style={{textAlign:'center'}}>
  <Typography variant="h1" style={{ fontSize: '64px' }}>404</Typography>
  <Typography variant="h2" style={{ fontSize: '24px' }}>Page Not Found</Typography>
</Box>

 </div>

  
  )
}
